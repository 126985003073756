import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

const TermsAndConditions = () => (
  <Layout pageInfo={{ pageName: "terms-and-conditions", pageTitle: "Algemene voorwaarden" }}>
    <Seo title="Terms and Conditions"/>

    <section className="general-page" id="terms-and-conditions">
      <Container>
        <Row>
          <Col>
            <div className="section-heading">
              <h2 className="title">Artikel 1 - Definities</h2>
            </div>

            <div className="block">
              <p>
                1.1 Onder aanbieder wordt in deze voorwaarden verstaan Wondere Wereld van Tekeningen, opgericht door
                Yvonne van Seters-Bogaard, gevestigd te Steenbergen.
              </p>
              <p>
                1.2 Onder opdrachtgever wordt in deze voorwaarden verstaan iedere (rechts)persoon of
                (rechts)personen die met Wondere Wereld van Tekeningen een overeenkomst heeft of hebben gesloten
                respectievelijk wenst te sluiten en derhalve deze, diens vertegenwoordiger(s), gemachtigde(n),
                rechtverkrijgende(n) en erfgena(a)m(en).
              </p>

              <p>
                1.3 Onder overeenkomst wordt in deze voorwaarden verstaan de overeenkomst voor de levering van
                diensten tussen aanbieder en opdrachtgever en alle verdere handelingen tussen aanbieder en afnemer
                betreffende de levering van diensten.
              </p>

              <p>
                1.4 Onder diensten wordt in deze voorwaarden verstaan de door aanbieder aan opdrachtgever te leveren
                of geleverde diensten.
              </p>

            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 2 - Algemeen</h2>
            </div>

            <div className="block">
              <p>
                2.1 Deze algemene voorwaarden zijn van toepassing op iedere levering van diensten door aanbieder aan
                opdrachtgever, alle daarop betrekking hebbende overeenkomsten en alle daarmee verband houdende
                handelingen, zowel van voorbereidende als uitvoerende aard.
              </p>

              <p>
                2.2 Afwijkingen van deze voorwaarden zijn alleen bindend als ze schriftelijk of per e-mail zijn
                bevestigd door Wondere Wereld van Tekeningen.
              </p>

              <p>
                2.3 Door het invullen van het contactformulier op de website of beantwoording van de e-mail van Wondere
                Wereld van Tekeningen verklaart de opdrachtgever akkoord te zijn gegaan met de algemene voorwaarden van
                Wondere Wereld van Tekeningen. Op zowel de website als bij het contactformulier is een link aanwezig
                naar de algemene voorwaarden van Wondere Wereld van Tekeningen: www.wonderewereldvantekeningen.nl.
              </p>

              <p>
                2.4 Indien anders overeengekomen zijn de afspraken gemaakt en bevestigd via de e-mail en via het
                contactformulier op de website bindend.
              </p>

              <p>
                2.5 Alle gegevens zoals vermeld in de overeenkomst worden door de opdrachtgever nagekeken en bij
                eventueel onjuistheden worden de correcties doorgegeven aan Wondere Wereld van Tekeningen, die zal
                zorgdragen voor een correcte verwerking hiervan.
              </p>

              <p>
                2.6 Indien enige bepaling van deze algemene voorwaarden of de overeenkomst om welke reden dan ook niet
                geldig is, blijven de bepalingen hierin voor het overige van kracht.
              </p>

              <p>
                2.7 Indien enige bepaling van de algemene voorwaarden of de overeenkomst om welke reden dan ook niet
                geldig is, zullen aanbieder en opdrachtgever over de inhoud van een nieuwe bepaling onderhandelen, welke
                bepaling de inhoud van de oorspronkelijke bepaling zo dicht mogelijk benadert.
              </p>

              <p>
                2.8 Onder de term ‘schriftelijk’ ten aanzien van communicatie tussen aanbieder en opdrachtgever wordt
                tevens verstaan elektronische communicatie. Het elektronische systeem van aanbieder wordt geacht het
                enige bewijs te vormen van de inhoud en het tijdstip van ontvangst en verzending van de betreffende
                elektronische communicatie.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 3 - Totstandkoming van de overeenkomst</h2>
            </div>

            <div className="block">
              <p>
                3.1 Deze algemene voorwaarden zijn van toepassing op alle rechtsverhoudingen tussen aanbieder en
                opdrachtgever en alle daarmee verband houdende handelingen.
              </p>

              <p>
                3.2 Aanbiedingen van aanbieder zijn niet bindend totdat tussen aanbieder en opdrachtgever een
                overeenkomst tot stand is gekomen.
              </p>

              <p>
                3.3 De toepasselijkheid van eventuele andere voorwaarden van opdrachtgever wordt uitdrukkelijk van de
                hand gewezen.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 4 - Tarieven</h2>
            </div>

            <div className="block">
              <p>
                4.1 De tarieven genoemd in de overeenkomst zijn inclusief BTW, tenzij anders aangegeven.
              </p>

              <p>
                4.2 De in de overeenkomst genoemde tarieven gelden niet automatisch voor toekomstige opdrachten.
              </p>

              <p>
                4.3 Wondere Wereld van Tekeningen behoudt te allen tijde het recht tot prijswijzigingen tenzij opgenomen
                in de overeenkomst.
              </p>

              <p>
                4.4 De hoogte van het tarief of betaling van de factuur is niet afhankelijk van de uitkomst van de
                verleende opdracht.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 5 - Betaling</h2>
            </div>

            <div className="block">
              <p>
                5.1 Opdrachtgever dient de verschuldigde bedragen inclusief BTW uiterlijk op de overeenkomen betaaldata
                respectievelijk binnen de overeengekomen betalingstermijn te hebben voldaan. Opdrachtgever is niet
                gerechtigd haar betalingsverplichting op te schorten, ook niet in geval van klachten.
              </p>

              <p>
                5.2 Indien voor de levering van diensten vooruit moet worden betaald, kan opdrachtgever geen recht doen
                gelden op levering hiervan alvorens volledige betaling van het verschuldigde aan aanbieder heeft
                plaatsgevonden.
              </p>

              <p>
                5.3 Aanbieder zendt opdrachtgever facturen voor door haar geleverde of (in geval van vooruitbetaling)
                nog te leveren diensten.
              </p>

              <p>
                5.4 Aanbieder is gerechtigd facturen elektronisch te zenden op het door opdrachtgever aangegeven
                e-mailadres.
              </p>

              <p>
                5.5 Indien geen andere betalingstermijn is overeengekomen, dienen facturen te worden voldaan binnen 14
                dagen na factuurdatum.
              </p>

              <p>
                5.6 Betaling dient netto plaats te vinden op de bankrekening van aanbieder, zonder enige korting,
                inhouding of verrekening. De op de bankafschriften van aanbieder aangegeven valutadag wordt als dag van
                betaling aangemerkt.
              </p>

              <p>
                5.7 Indien opdrachtgever niet binnen een overeengekomen betalingstermijn of uiterlijk op een
                overeengekomen betaaldatum het volledige verschuldigde bedrag heeft betaald, is opdrachtgever van
                rechtswege in verzuim, zonder dat enige ingebrekestelling noodzakelijk is.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 6 - Uitvoering van de overeenkomst</h2>
            </div>
            <div className="block">
              <p>
                6.1 Aanbieder bepaalt de wijze waarop de overeenkomst wordt uitgevoerd. Aanbieder zal de overeenkomst
                naar beste inzicht en vermogen en in overeenstemming met de eisen van goed vakmanschap uitvoeren.
              </p>

              <p>
                6.2 Aanbieder heeft een inspanningsverplichting jegens opdrachtgever, nooit een resultaatverplichting.
              </p>

              <p>
                6.3 Aanbieder kan slechts meer werkzaamheden verrichten en aan opdrachtgever in rekening brengen dan
                waartoe opdracht is verstrekt indien opdrachtgever hiervoor vooraf toestemming heeft verleend.
              </p>

              <p>
                6.4 Aanbieder zal in het belang van de uitvoering van de opdracht uitsluitend met toestemming van de
                opdrachtgever in overleg treden met derden.
              </p>

              <p>
                6.5 Opdrachtgever draagt er zorg voor dat alle gegevens, waarvan aanbieder aangeeft dat deze
                noodzakelijk zijn of waarvan de opdrachtgever redelijkerwijs hoort te begrijpen dat deze noodzakelijk
                zijn voor de uitvoering van de overeenkomst, tijdig aan aanbieder worden verstrekt. Indien de voor de
                uitvoering van de overeenkomst benodigde gegevens niet tijdig aan aanbieder zijn verstrekt, heeft
                aanbieder het recht de uitvoering van de overeenkomst op te schorten en/of de uit de vertraging
                voortvloeiende extra kosten volgens de gebruikelijke tarieven aan opdrachtgever in rekening brengen.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 7 - Annulering en wijziging</h2>
            </div>
            <div className="block">
              <p>
                7.1 Aanbieder en opdrachtgever kunnen te allen tijde de overeenkomst opzeggen, tenzij anders is
                overeenkomen. Opzeggen dient schriftelijk te worden meegedeeld.
              </p>

              <p>
                7.2 Aanbieder behoudt zich het recht voor afspraken af te zeggen of te verzetten in geval van ziekte,
                arbeidsongeschiktheid, sterfgeval of ernstige ziekte van familie of dierbare, waardoor aanbieder de
                overeenkomst niet naar behoren kan uitvoeren. In dat geval zullen aanbieder en opdrachtgever zo snel als
                redelijkerwijs mogelijk is een nieuwe afspraak maken. Aan de opdrachtgever worden geen kosten in
                rekening gebracht voor een geannuleerde afspraak.
              </p>

              <p>
                7.3 Aanbieder is gerechtigd om zonder opgave van redenen een overeenkomst tussentijds te beëindigen.
                Alsdan heeft opdrachtgever recht op terugbetaling van door hem/haar betaalde bedragen onder aftrek van
                verschuldigde bedragen voor de inmiddels al geleverde prestaties.
              </p>

              <p>
                7.4 Wanneer opdrachtgever een reeds gemaakte afspraak met aanbieder wenst te annuleren, dient dit
                uiterlijk 24 uur van tevoren telefonisch aan aanbieder te worden doorgegeven. Indien de annulering
                korter dan 24 uur van tevoren plaatsvindt, of in het geval dat er in het geheel geen annulering heeft
                plaatsgevonden, is aanbieder gerechtigd 100% van het geldende tarief aan opdrachtgever in rekening te
                brengen.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 8 - Geheimhouding</h2>
            </div>
            <div className="block">
              <p>
                8.1 Aanbieder is verplicht tot geheimhouding van alle vertrouwelijke informatie die zij in het kader van
                de overeenkomst van opdrachtgever, het kind of uit andere niet-openbare bron heeft verkregen.
              </p>

              <p>
                8.2 Informatie geldt als vertrouwelijk als dit door aanbieder, opdrachtgever, het kind of andere
                niet-openbare bron als zodanig is gekwalificeerd of als dit voortvloeit uit de aard van de informatie.
              </p>

              <p>
                8.3 Indien, op grond van een wettelijke bepaling of rechterlijke uitspraak, aanbieder gehouden is
                vertrouwelijke informatie aan door de wet of de bevoegde rechter aangewezen derden te verstrekken, dan
                is aanbieder hiertoe te allen tijde gerechtigd.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 9 - Persoonsgegevens</h2>
            </div>
            <div className="block">
              <p>
                9.1 Aanbieder behandelt persoonsgegevens die zij ontvangt in het kader van de overeenkomst met
                opdrachtgever strikt vertrouwelijk en in overeenstemming met gelde privacywet- en regelgeving.
              </p>

              <p>
                9.2 Aanbieder neemt in een klantenbestand de naam- en adresgegevens op van opdrachtgever. Deze worden
                gebruikt ter uitvoering van een overeenkomst en kunnen ook worden gebruikt om opdrachtgever op de hoogte
                te houden van andere dienstverlening door aanbieder.
              </p>

              <p>
                9.3 Opdrachtgever stemt in met het gebruik van de betreffende gegevens voor het hiervoor omschreven
                doel.
              </p>

              <p>
                9.4 Indien opdrachtgever geen prijs stelt op informatie over (nieuwe) diensten dan kan hij of zij dit te
                allen tijde laten weten aan aanbieder. Aanbieder zal de informatievoorziening dan stopzetten.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 10 - Aansprakelijkheid</h2>
            </div>
            <div className="block">
              <p>
                10.1 Aanbieder spant zich naar beste inzicht en vermogen in bij de uitvoering van haar werkzaamheden.
                Het uiteindelijke resultaat hiervan is echter ook afhankelijk van factoren waarop aanbieder geen invloed
                kan uitoefenen. Aanbieder verstrekt geen garantie met betrekking tot het resultaat van door haar
                verrichte werkzaamheden.
              </p>

              <p>
                10.2 Opdrachtgever is verantwoordelijk voor zichzelf en zijn of haar daden en handelingen. De geleverde
                diensten kunnen niet worden beschouwd als, of ter vervanging van, medische en/of psychische consulten
                en/of behandelingen. Wondere Wereld van Tekeningen is nooit aansprakelijk voor directe of indirecte
                schade, emotionele schade of schade voortvloeiend uit beslissingen die opdrachtgever heeft genomen, al
                dan niet in overleg. Opdrachtgever is te allen tijde zelf verantwoordelijk voor gemaakte keuzes en de
                gevolgen daarvan.
              </p>

              <p>
                10.3 De aansprakelijkheid van aanbieder is te allen tijde beperkt tot maximaal het bedrag dat de
                opdrachtgever in het kader van de overeenkomst aan aanbieder heeft betaald.
              </p>

              <p>
                10.4 Indien en voor zover op aanbieder enige aansprakelijkheid zou komen te rusten, op welke grond dan
                ook, is deze te allen tijde beperkt tot directe schade en gelimiteerd tot de uitkering onder de
                aansprakelijkheidsverzekering van aanbieder die de betreffende schade dekt en tot uitkering overgaat.
              </p>

              <p>
                10.5 Iedere vordering tot schadevergoeding verjaart door verloop van één jaar na de aanvang van de dag,
                volgende op die waarop de afnemer met de schade en met aanbieder als de daarvoor aansprakelijke persoon
                bekend is geworden.
              </p>

              <p>
                10.6 Indien aanbieder een niet aan haar verbonden persoon bij de uitvoering van de overeenkomst
                inschakelt, dan is aanbieder tegenover opdrachtgever niet aansprakelijk voor enige fout die door deze
                persoon mocht worden gemaakt. Door aanbieder een opdracht te geven, verleent opdrachtgever aan aanbieder
                de bevoegdheid om, als een door aanbieder ingeschakelde persoon zijn aansprakelijkheid wil beperken, die
                aansprakelijkheidsbeperking mede namens opdrachtgever te aanvaarden.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 11 - Toepasselijk recht</h2>
            </div>
            <div className="block">
              <p>
                11.1 Op alle rechtsverhoudingen tussen aanbieder en opdrachtgever is Nederlands recht van toepassing.
              </p>
              <p>
                11.2 Eventuele geschillen die verband houden met deze rechtsverhoudingen worden beslecht volgens een
                door de wet bevoegde rechter.
              </p>
              <p>
                11.3 Opdrachtgever zal zich, wanneer zich een geschil voordoet, contact opnemen met aanbieder. Aanbieder
                en opdrachtgever zullen pas een beroep doen op een rechter nadat zij zich tot het uiterste hebben
                ingespannen een geschil in onderling overleg te beslechten.
              </p>
            </div>


            <div className="section-heading">
              <h2 className="title">Artikel 12 - Auteursrecht</h2>
            </div>
            <div className="block">
              <p>
                Het materiaal en aanvullende documentatie van Wondere Wereld van Tekeningen zijn auteursrechtelijk
                beschermd en mag/mogen niet zonder voorafgaande schriftelijke toestemming worden bewerkt, verveelvoudigd
                of openbaar gemaakt, via welk medium dan ook aan derden ter inzage of ter beschikking gesteld worden.
                Tevens kunnen aan de inhoud van de website op geen enkele wijze rechten worden ontleend.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 13 - Wijzigingen</h2>
            </div>
            <div className="block">
              <p>
                Aanbieder is gerechtigd deze algemene voorwaarden te wijzigen. Opdrachtgever wordt geacht de betreffende
                wijzigingen te hebben aanvaard indien aanbieder niet binnen 14 dagen na mededeling van aanbieder dat
                wijziging zal plaatsvinden een schriftelijk protest daartegen heeft ontvangen.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 14 - Slotbepaling</h2>
            </div>
            <div className="block">
              <p>
                In gevallen waarin deze algemene voorwaarden niet voorzien, beslist de aanbieder na overleg met de
                opdrachtgever.
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default TermsAndConditions
